@font-face {
  font-family: 'JosefinSansRegular';
  src: local('JosefinSansRegular'),
    url(./fonts/JosefinSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'JosefinSansLight';
  src: local('JosefinSansLight'),
    url(./fonts/JosefinSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'JosefinSansBold';
  src: local('JosefinSansBold'),
    url(./fonts/JosefinSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'JosefinSlabSemiBold';
  src: local('JosefinSlabSemiBold'),
    url(./fonts/JosefinSlab-SemiBold.ttf) format('truetype');
}

#root {
  background-color: #007884;
}

body {
  background-color: #007884;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.homepage-view {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 7%;
}

.std-header {
  display: flex;
  color: white;
  font-size: 7vw;
  font-family: 'JosefinSansBold';
}

.std-subtext {
  padding-top: 68vh;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  font-size: 3vw;
  font-family: 'JosefinSansRegular';
}

.std-subheader {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #bbb082;
  font-size: 1.6vw;
  font-family: 'JosefinSlabSemiBold';
}

.date-home-screen {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 7%;
  color: white;
}

.day-home-screen {
  font-size: 7.72vw;
  font-family: 'JosefinSansLight';
  padding: 0px 25px 0px 25px;
}

.left-border-line-home-screen {
  display: flex;
  align-items: center;
  border-right: 1px solid white;
  height: 5vw;
  margin-bottom: 1vw;
  padding-left: 25px;
}

.right-border-line-home-screen {
  display: flex;
  align-items: center;
  border-left: 1px solid white;
  height: 5vw;
  margin-bottom: 1vw;
  padding-right: 25px;
}

.month-year-home-screen {
  font-size: 3.2vw;
  font-family: 'JosefinSansBold';
}

.top-border-line {
  margin-top: 4%;
  border-bottom: 1px dotted #bbb082;
  width: 31vw;
}

.bottom-border-line {
  border-bottom: 1px dotted #bbb082;
  width: 31vw;
}

/* #main-body {
  background-image: url('../src/assets/images/2_Kopie_NEW.png');
  background-repeat: no-repeat;
} */

.location {
  font-family: 'JosefinSansLight';
  color: white;
  font-size: 2vw;
  letter-spacing: 3px;
  margin-top: 1%;
}

.invite-text {
  font-family: 'JosefinSansLight';
  color: #bbb082;
  font-size: 1.2vw;
  letter-spacing: 1px;
  margin-top: 8%;
  margin-bottom: 1%;
}

.stay-tuned-text {
  font-family: 'JosefinSansLight';
  color: #bbb082;
  font-size: 1.2vw;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu {
  color: #bbb082;
  margin: 0vw 6vw 3vw 6vw;
  border-bottom: 2px dotted #bbb082;
  padding-top: 3vw;
}

.inner-menu {
  display: flex;
  justify-content: space-between;
  margin: 0vw 2vw 2vw 2vw;
  font-size: 1.5em;
}

a.menu-item {
  color: white;
  font-family: 'JosefinSlabSemiBold';
  font-size: 16px;
}

a.menu-item:hover {
  color: #bbb082;
}

a.menu-item.active {
  color: #bbb082;
}

i.big.icon.content {
  font-size: 3em;
}

.header-names {
  font-size: 4vw;
}

.photo-image {
  height: 33vw;
}

.home-screen-text {
  align-self: 'center';
  top: 50%;
  left: 40%;
  position: absolute;
}

.home-screen-text > .std-header {
  display: flex;
  font-size: 40px;
  font-family: 'JosefinSansBold';
}

.home-screen-text > .date {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 7%;
  justify-content: center;
}

#year {
  font-size: 20px;
}

.home-screen-text > .date > .day {
  font-size: 32px;
}

.home-screen-text > .date > .month-year,
.day,
.month-year {
  color: #bbb082;
  display: flex;
  justify-content: center;
  align-self: center;
}

.home-screen-text > .date > .month-year {
  font-family: 'JosefinSansBold';
  font-size: 18px;
}

.home-screen-text > .date > .left-border-line,
.right-border-line {
  height: 2vw;
  margin-bottom: 1px;
}

.home-screen-text > .date > .day {
  padding: 0px 10px 0px 10px;
  font-size: 2.5vw;
  margin-bottom: 0.5vw;
}

.home-screen-text > .date > .left-border-line {
  border-right: 0.5px solid #bbb082;
  padding-left: 10px;
  margin-bottom: 0.5vw;
}

.home-screen-text > .date > .right-border-line {
  border-left: 0.5px solid #bbb082;
  padding-right: 10px;
  margin-bottom: 0.5vw;
}

.outer-ui-form {
  margin: 0vw 6vw 0vw 6vw;
}

.ui.form {
  margin: 0vw 2vw;
  padding: 5vw 12vw 0vw 12vw;
}

.ui.form .field > label {
  color: #bbb082 !important;
  font-family: 'JosefinSansRegular' !important;
  font-size: 1.1em !important;
}

div.field {
  color: #bbb082 !important;
  font-family: 'JosefinSansRegular' !important;
  font-size: 1.1em !important;
  font-weight: 700;
}

input[type='text'] {
  background-color: #33858c !important;
  border: 1px solid white !important;
  color: white !important;
  font-family: 'JosefinSansRegular' !important;
}

textarea {
  background-color: #33858c !important;
  border: 1px solid white !important;
  color: white !important;
  font-family: 'JosefinSansRegular' !important;
}

.ui.checkbox input.hidden + label {
  color: white !important;
  font-family: 'JosefinSansLight' !important;
  font-size: 1.5em !important;
}

button.ui.button {
  border: 1px solid white;
  background-color: #bbb082;
  color: #007884;
  font-family: 'JosefinSlabSemiBold';
  font-size: 1.5vw;
}

input[type='checkbox'] + label::before {
  background-color: #33858c !important;
  color: white !important;
}

input[type='checkbox'] + label::after {
  color: white !important;
}

.field {
  padding: 0.5vw 0vw 0vw 0vw;
}

.fields {
  padding: 0.5vw 0vw 0vw 0vw;
}

.field.submit-button {
  padding: 2vw 0vw 4vw 0vw;
}

div.field.attend {
  padding: 2vw 0vw 0vw 0vw;
  font-size: 1.1em !important;
}

.pooper {
  padding-left: 5vw !important;
}

/* WELCOME PAGE STYLIMG */

.welcome-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f0f5f4;
  padding: 5% 23% 5% 23%;
  text-align: center;
}

.welcome-header-text {
  font-size: 24px;
  color: #bbb082;
  font-family: 'JosefinSlabSemiBold';
  padding-bottom: 3vw;
  padding-top: 2vw;
}

.welcome-text-paragraph {
  font-size: 20px;
  font-family: 'JosefinSansLight';
  line-height: 35px;
  padding-bottom: 3vw;
}

.welcome-text-paragraph-thanks {
  font-size: 20px;
  font-family: 'JosefinSansLight';
  line-height: 35px;
}

.welcome-rsvp-text {
  font-size: 25px;
  font-family: 'JosefinSansbold';
  font-weight: bold;
  padding-bottom: 3vw;
  letter-spacing: 1px;
}

/* EVENT PAGE STYLIMG */

.event-gold-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #bbb082;
  font-size: 20px;
  line-height: 35px;
  font-family: 'JosefinSansBold';
}

.dotted-line-separator {
  margin-left: auto;
  margin-right: auto;
  color: white;
  border-top: 2px dotted white;
  padding-bottom: 6vw;
  width: 50%;
}

.event-sign-up {
  color: white;
  font-family: 'JosefinSansRegular';
  padding-bottom: 3vw;
}

.event-white-date {
  color: white;
  font-family: 'JosefinSansBold';
  font-size: 20px;
  line-height: 35px;
}

.events-reserve-date {
  font-family: 'JosefinSansRegular';
}

a.events-href:hover {
  color: #cf7f24 !important;
}

/* LODING STYLING PAGE */

.welcome-text-paragraph-thanks.code {
  font-family: 'JosefinSansRegular';
}

/* RSVP STYLING PAGE */
.ui.form .required.field > .checkbox:after,
.ui.form .required.field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.fields:not(.grouped) > .field > label:after {
  color: #cf7f24 !important;
}

/* FOOTER STYLING */

.footer {
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'JosefinSansLight';
  font-size: 18px;
  padding-bottom: 40px;
}

.footer-email {
  color: white;
  font-family: 'JosefinSansLight';
}

.dotted-line-separator-footer {
  margin-left: auto;
  margin-right: auto;
  color: white;
  border-top: 2px dotted white;
  padding: 1% 23% 0% 23%;
  width: 50%;
}

.welcome-text.footer {
  padding: 0% 23% 2% 23%;
}

@media screen and (max-width: 650px) {
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 33vw;
    left: 0;
    background-color: #15646b;
    overflow-x: hidden;
    transition: 1s;
    padding-top: 60px;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #bbb082;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #bbb082;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 3px;
    right: 11px;
    font-size: 46px;
    margin-left: 50px;
  }

  a.menu-item {
    color: white;
    font-family: 'JosefinSlabSemiBold';
    white-space: nowrap;
  }

  a.menu-item.active {
    color: #bbb082;
  }

  a.closebtn {
    color: #bbb082;
    font-family: 'JosefinSlabSemiBold';
  }

  .home-screen-text {
    top: 7%;
    left: 17%;
    position: absolute;
  }

  .home-screen-text > .std-header {
    display: flex;
    color: white;
    font-size: 8.5vw;
    font-family: 'JosefinSansBold';
  }

  .home-screen-text > .date {
    display: flex;
    justify-content: center;
    padding-top: 7%;
  }

  .home-screen-text > .date > .month-year,
  .day,
  .month-year {
    color: #bbb082;
  }

  .home-screen-text > .date > .day {
    font-size: 7.72vw;
    font-family: 'JosefinSansRegular';
    padding: 0px 5px 0px 5px;
    margin-bottom: 0px;
  }

  .home-screen-text > .date > .left-border-line {
    display: flex;
    align-items: center;
    height: 7vw;
    margin-bottom: 1vw;
    border-right: 0.1px solid #bbb082;
    padding-left: 10px;
  }

  .home-screen-text > .date > .right-border-line {
    display: flex;
    align-items: center;
    height: 7vw;
    margin-bottom: 1vw;
    border-left: 0.1px solid #bbb082;
    padding-right: 10px;
  }

  .home-screen-text > .date > .month-year {
    font-size: 5.5vw;
    font-family: 'JosefinSansBold';
  }

  #year {
    font-size: 6vw;
  }

  .photo-image {
    height: 33vw;
  }

  .welcome-text {
    font-size: 3vw;
    padding: 10% 15% 0% 15%;
  }

  .pooper {
    padding-left: 0vw !important;
  }

  /* WELCOME MOBILE STYLING */

  .welcome-header-text {
    line-height: 35px;
    padding-bottom: 6vw;
  }

  .welcome-rsvp-text {
    font-size: 22px;
    font-family: 'JosefinSansbold';
    font-weight: bold;
    padding-bottom: 10vw;
    letter-spacing: 1px;
    line-height: 35px;
  }

  .welcome-text-paragraph {
    padding-bottom: 10vw;
  }

  /* RSVP SECTION */

  .ui.form .inline.field > :only-child,
  .ui.form .inline.fields .field > :only-child {
    padding-top: 5vw;
  }

  .dotted-line-separator {
    padding-bottom: 18vw;
  }

  .fields.rsvp-input {
    padding-top: 15px;
    line-height: 25px;
  }

  .fields.food-input {
    line-height: 25px;
  }

  /* FOOTER SECTION */

  .footer {
    padding-top: 20px;
  }

  .welcome-text.footer {
    padding-top: 20px;
  }
}
